@use "../util" as *;

.toggle{
    display: grid;
    grid-template-columns: 1fr 3rem;
    border: none;
    gap:rem(4.8) rem(8);
    margin: 0;
    label{
        font-size: rem(14);
        color: var(--toggle-light);
        font-weight: 700;

        &[for="dark"]{
            line-height:rem(24);
        }
    }

    &__wrapper{
        position: relative;
        grid-column: 2 / 3;
        height: rem(24);
    }

    input[type="radio"]{
        opacity: 0;
        margin: 0 rem(-2) 0 rem(-2);
        width: rem(24);
        height: rem(24);

        &:focus ~ .toggle__button{
            border: rem(2) solid white;
        }
    }
    &__background{
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        display: block;
        border-radius: rem(12);
         overflow: hidden;
       background:var(--toggle-bg);
       pointer-events: none;
    }
    &__button{
        position: absolute;
        left: rem(3);
        top: rem(3);
        width: rem(18);
        height:rem(18);
        border-radius:50%;
        background-color: var(--dark-bg);
        transition: all 150ms ease-in-out;
        
    }
    #light:checked ~ .toggle__button{
        left: calc(100% - 21px);
        right: 3px;
    }
    #system:checked ~ .toggle__button{
        left: 50%;
        right: auto;
        transform: translate(-50%);
    }
}