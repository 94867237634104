@use "../util" as *;

.container{
    max-width:rem(1110);
    padding:0 rem(25);
    margin :0 auto rem(46);

    @include breakpoint(large){
        padding:0;
    }
}
.attribution{
    margin-bottom: rem(20);
}